import { css } from 'lit';
import { css } from 'lit';
// tailwind-inspried utility classes

export default css`
	/* width */
	.w-xxs {
		width: var(--xxs);
	}
	.w-xs {
		width: var(--xs);
	}
	.w-sm {
		width: var(--sm);
	}
	.w-md {
		width: var(--md);
	}
	.w-lg {
		width: var(--lg);
	}
	.w-xl {
		width: var(--xl);
	}
	.w-xxl {
		width: var(--xxl);
	}
	.w-25 {
		width: 25%;
	}
	.w-30 {
		width: 30%;
	}
	.w-50 {
		width: 50%;
	}
	.w-100 {
		width: 100%;
	}
	.w-min-0 {
		min-width: 0;
	}

	/* heights */
	.h-xxs {
		height: var(--xxs);
	}
	.h-xs {
		height: var(--xs);
	}
	.h-sm {
		height: var(--sm);
	}
	.h-md {
		height: var(--md);
	}
	.h-lg {
		height: var(--lg);
	}
	.h-xl {
		height: var(--xl);
	}
	.h-xxl {
		height: var(--xxl);
	}
	.h-25 {
		height: 25%;
	}
	.h-50 {
		height: 50%;
	}
	.h-100 {
		height: 100%;
	}
	.h-min-0 {
		min-height: 0;
	}

	/* paddings */
	.p-xxs {
		padding: var(--xxs) !important;
	}
	.p-xs {
		padding: var(--xs) !important;
	}
	.p-sm {
		padding: var(--sm) !important;
	}
	.p-md {
		padding: var(--md) !important;
	}
	.p-lg {
		padding: var(--lg) !important;
	}
	.p-xl {
		padding: var(--xl) !important;
	}
	.p-xxl {
		padding: var(--xxl) !important;
	}
	.p-v-xxs {
		padding: var(--xxs) 0 !important;
	}
	.p-v-xs {
		padding: var(--xs) 0 !important;
	}
	.p-v-sm {
		padding: var(--sm) 0 !important;
	}
	.p-v-md {
		padding: var(--md) 0 !important;
	}
	.p-v-lg {
		padding: var(--lg) 0 !important;
	}
	.p-v-xl {
		padding: var(--xl) 0 !important;
	}
	.p-v-xxl {
		padding: var(--xxl) 0 !important;
	}
	.p-h-xxs {
		padding: 0 var(--xxs) !important;
	}
	.p-h-xs {
		padding: 0 var(--xs) !important;
	}
	.p-h-sm {
		padding: 0 var(--sm) !important;
	}
	.p-h-md {
		padding: 0 var(--md) !important;
	}
	.p-h-lg {
		padding: 0 var(--lg) !important;
	}
	.p-h-xl {
		padding: 0 var(--xl) !important;
	}
	.p-h-xxl {
		padding: 0 var(--xxl) !important;
	}
	.p-auto {
		padding: auto;
	}
	.p-0 {
		padding: 0;
	}
	.pb-0 {
		padding-bottom: 0 !important;
	}
	/* margins */
	.m-xxs {
		margin: var(--xxs);
	}
	.m-xs {
		margin: var(--xs);
	}
	.m-sm {
		margin: var(--sm);
	}
	.m-md {
		margin: var(--md);
	}
	.m-lg {
		margin: var(--lg);
	}
	.m-xl {
		margin: var(--xl);
	}
	.m-xxl {
		margin: var(--xxl);
	}
	.m-auto {
		margin: auto;
	}
	.m-0 {
		margin: 0 !important;
	}
	.mb-0 {
		margin-bottom: 0;
	}
	.m-0 {
		margin: 0;
	}

	/* flexbox */
	.flex {
		display: flex;
	}
	.flex-1 {
		flex: 1 1 0%;
	}
	.flex-50 {
		flex: 1 1 50%;
	}
	.flex-auto {
		flex: 1 1 auto;
	}
	.flex-initial {
		flex: 0 1 auto;
	}
	.flex-0 {
		flex: 0 1 0;
	}
	.flex-no-shrink {
		flex: 1 0 auto;
	}
	.flex-none {
		flex: none;
	}
	.flex-wrap-wrap {
		flex-wrap: wrap;
	}
	.row {
		flex-direction: row;
	}
	.col {
		flex-direction: column;
	}
	.gap {
		gap: var(--ggutter);
	}
	.gap-half {
		gap: calc(var(--ggutter) / 2);
	}
	.gap-double {
		gap: calc(var(--ggutter) * 2);
	}
	/* justification */
	.justify-content-start {
		justify-content: start;
	}
	.justify-content-end {
		justify-content: end;
	}
	.justify-content-center {
		justify-content: center;
	}
	.justify-content-stretch {
		justify-content: stretch;
	}
	.justify-content-space-between {
		justify-content: space-between;
	}
	.justify-content-space-evenly {
		justify-content: space-evenly;
	}
	.justify-content-flex-start {
		justify-content: flex-start;
	}
	.justify-content-flex-end {
		justify-content: flex-end;
	}

	/* alignment */
	.align-items-start {
		align-items: start;
	}
	.align-items-end {
		align-items: end;
	}
	.align-items-center {
		align-items: center;
	}
	.align-items-stretch {
		align-items: stretch;
	}
	.align-self-center {
		align-self: center;
	}
	.align-self-flex-end {
		align-self: flex-end;
	}
	.align-items-flex-end {
		align-items: flex-end;
	}

	.left {
		margin-right: auto;
	}
	.right {
		margin-left: auto;
	}
	/* position */
	.static {
		position: static;
	}
	.fixed {
		position: fixed;
	}
	.absolute {
		position: absolute;
	}
	.relative {
		position: relative;
	}
	.sticky {
		position: sticky;
	}

	/* borders */
	.bordered {
		border: 1px solid rgb(var(--brand-grey-dimmer)) !important;
		border-radius: 3px;
	}
	.bordered-input {
		border: var(--input-border);
	}
	.bordered-chip {
		border: var(--chip_border);
	}
	.borderless {
		border: 0 !important;
	}
	/* font sizes */
	.font-xxs {
		font-size: var(--xxs);
	}
	.font-xs {
		font-size: var(--xs);
	}
	.font-sm {
		font-size: var(--sm);
	}
	.font-md {
		font-size: var(--md);
	}
	.font-lg {
		font-size: var(--lg);
	}
	.font-xl {
		font-size: var(--xl);
	}
	.font-xxl {
		font-size: var(--xxl);
	}
	.bold {
		font-weight: bold;
	}
	.bolder {
		font-weight: bolder;
	}
	/* grid layout @see https://tailwindcss.com/docs/grid-template-columns */
	.grid {
		display: grid;
	}
	.grid-cols-1 {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	.grid-cols-2 {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	.grid-cols-3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	.grid-cols-4 {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	.grid-cols-5 {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
	.grid-cols-6 {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
	.grid-cols-7 {
		grid-template-columns: repeat(7, minmax(0, 1fr));
	}
	.grid-cols-8 {
		grid-template-columns: repeat(8, minmax(0, 1fr));
	}
	.grid-cols-9 {
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
	.grid-cols-10 {
		grid-template-columns: repeat(10, minmax(0, 1fr));
	}
	.grid-cols-11 {
		grid-template-columns: repeat(11, minmax(0, 1fr));
	}
	.grid-cols-12 {
		grid-template-columns: repeat(12, minmax(0, 1fr));
	}
	.grid-cols-none {
		grid-template-columns: none;
	}
	.col-auto {
		grid-column: auto;
	}
	.col-span-1 {
		grid-column: span 1 / span 1;
	}
	.col-span-2 {
		grid-column: span 2 / span 2;
	}
	.col-span-3 {
		grid-column: span 3 / span 3;
	}
	.col-span-4 {
		grid-column: span 4 / span 4;
	}
	.col-span-5 {
		grid-column: span 5 / span 5;
	}
	.col-span-6 {
		grid-column: span 6 / span 6;
	}
	.col-span-7 {
		grid-column: span 7 / span 7;
	}
	.col-span-8 {
		grid-column: span 8 / span 8;
	}
	.col-span-9 {
		grid-column: span 9 / span 9;
	}
	.col-span-10 {
		grid-column: span 10 / span 10;
	}
	.col-span-11 {
		grid-column: span 11 / span 11;
	}
	.col-span-12 {
		grid-column: span 12 / span 12;
	}
	.col-span-full {
		grid-column: 1 / -1;
	}
	.col-start-1 {
		grid-column-start: 1;
	}
	.col-start-2 {
		grid-column-start: 2;
	}
	.col-start-3 {
		grid-column-start: 3;
	}
	.col-start-4 {
		grid-column-start: 4;
	}
	.col-start-5 {
		grid-column-start: 5;
	}
	.col-start-6 {
		grid-column-start: 6;
	}
	.col-start-7 {
		grid-column-start: 7;
	}
	.col-start-8 {
		grid-column-start: 8;
	}
	.col-start-9 {
		grid-column-start: 9;
	}
	.col-start-10 {
		grid-column-start: 10;
	}
	.col-start-11 {
		grid-column-start: 11;
	}
	.col-start-12 {
		grid-column-start: 12;
	}
	.col-start-13 {
		grid-column-start: 13;
	}
	.col-start-auto {
		grid-column-start: auto;
	}
	.col-end-1 {
		grid-column-end: 1;
	}
	.col-end-2 {
		grid-column-end: 2;
	}
	.col-end-3 {
		grid-column-end: 3;
	}
	.col-end-4 {
		grid-column-end: 4;
	}
	.col-end-5 {
		grid-column-end: 5;
	}
	.col-end-6 {
		grid-column-end: 6;
	}
	.col-end-7 {
		grid-column-end: 7;
	}
	.col-end-8 {
		grid-column-end: 8;
	}
	.col-end-9 {
		grid-column-end: 9;
	}
	.col-end-10 {
		grid-column-end: 10;
	}
	.col-end-11 {
		grid-column-end: 11;
	}
	.col-end-12 {
		grid-column-end: 12;
	}
	.col-end-13 {
		grid-column-end: 13;
	}
	.col-end-auto {
		grid-column-end: auto;
	}
	.auto-cols-auto {
		grid-auto-columns: auto;
	}
	.auto-cols-min {
		grid-auto-columns: min-content;
	}
	.auto-cols-max {
		grid-auto-columns: max-content;
	}
	.auto-cols-fr {
		grid-auto-columns: minmax(0, 1fr);
	}
	.grid-flow-row {
		grid-auto-flow: row;
	}
	.grid-flow-col {
		grid-auto-flow: column;
	}
	.grid-flow-dense {
		grid-auto-flow: dense;
	}
	.grid-flow-row-dense {
		grid-auto-flow: row dense;
	}
	.grid-flow-col-dense {
		grid-auto-flow: column dense;
	}

	/* cursor */
	.cursor-pointer {
		cursor: pointer;
	}

	.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
	/* box sizing */
	.border-box {
		box-sizing: border-box;
	}
	.content-box {
		box-sizing: content-box;
	}

	.rotate-90 {
		transform: rotate(90deg);
	}
	.rotate-180 {
		transform: rotate(180deg);
	}
	.rotate-270 {
		transform: rotate(270deg);
	}
	/* overflow */
	.overflow-wrap-anywhere {
		overflow-wrap: anywhere;
	}
	.overflow-y-scroll {
		overflow-y: scroll;
	}
	.overflow-hidden {
		overflow: hidden;
	.text-wrap-wrap {
		text-wrap: wrap;
	}
`;
