import { css } from 'lit';
/**
 * @file A collection of standalone functions
 */

import { ComboBoxOption } from 'cra-web-components/components/combo-box/combo-box';
import { ValidationResult } from 'cra-web-components/components/form-base/cra-form-field';
import convert from 'convert';
import { EventTypes } from '@/config/ConfigREMEventTypes';
import {
	LaneBlockage,
	QuantityDto,
	QuantityTypeDto,
	RespondingUnitDto,
	RoadEventDto,
} from '../../typings/api';
import { CartesianCoordinates } from '../../typings/shared-types';
import { Dimensions, Region } from '../constants';

/**
 * A pair of Cartesian coordinates
 *
 * @typedef {Object} Point
 * @property {number} x signed float, measuring distance along a horizontal axis
 * @property {number} y signed float, measuring distance along a vertical axis
 */

/**
 * @function roundUpToSquare
 * @description Finds the next square number after the supplied float.
 * @static
 * @global
 * @param {number} i Float.
 * @returns {number}
 * @example
 * roundUpToSquare(3); // 4, aka 2x2
 * roundUpToSquare(5); // 9, aka 3x3
 */
export function roundUpToSquare(i: number): number {
	const squareRoot = Math.sqrt(i); //  take the square root
	const rootRoundedDown = Math.ceil(squareRoot); //  round up the result
	const finalSquare = rootRoundedDown ** 2; //  and square it
	return finalSquare;
}

/**
 * @function gridIndexToXYCoords
 * @description Assuming cells in a square grid are numbered left-right-right, top-to-bottom,
 * with the top left being '0' - takes such an index and the size of the grid, and return the
 * X and Y coordinates of that cell.
 * @static
 * @global
 * @param {number} index Integer, the index of a cell in a square grid.
 * @param {number} gridSize An integer, the size of one dimension of a square grid.
 * @returns {Point}
 * @example
 *
 * gridIndexToXYCoords(0, 1); // the first cell in a 1x1 grid -> {x:0,y:0}, aka upper left corner
 * gridIndexToXYCoords(5, 3); // the sixth cell in a 3x3 grid -> {x:2,y:1}, aka middle right cell
 * // cell 5, in a 3x3 grid:
 * // [0][1][2]
 * // [3][4][5] <--- 2,1
 * // [6][7][8]
 */
export function gridIndexToXYCoords(index: number, gridSize: number) {
	const x = index % gridSize;
	const y = Math.floor(index / gridSize);
	return { x, y };
}

/**
 * @function gridXYCoordsToIndex
 * @description Assuming cells in a square grid are numbered left-to-right, top-to-bottom,
 * with the top left being '0' - takes the X and Y coordinates to such a cell and the size of
 * the grid, and return the index of that cell.
 * @static
 * @global
 * @param {Point} XY A pair of integers, the X and Y coordinates of a cell in a square grid.
 * @param {number} gridSize An integer, the size of one dimension of a square grid.
 * @returns {number} returns -1 if the coordinates are out of bounds
 * @example
 * gridXYCoordsToIndex({ x: 0, y: 0 }, 1); // upper left corner in a 1x1 grid -> 0, aka first cell
 * gridXYCoordsToIndex({ x: 2, y: 1 }, 3); // middle right cell in a 3x3 grid -> 5, aka sixth cell
 * // cell 2,1 in a 3x3 grid:
 * // [0][1][2]
 * // [3][4][5] <--- 5
 * // [6][7][8]
 */
export function gridXYCoordsToIndex({ x, y }: CartesianCoordinates, gridSize: number): number {
	if (x >= gridSize) {
		throw new Error(
			`gridXYCoordsToIndex: x value "${x}" out of bounds, must be less than grid size "${gridSize}"`,
		);
	}
	if (y >= gridSize) {
		throw new Error(
			`gridXYCoordsToIndex: y value "${y}" out of bounds, must be less than grid size "${gridSize}"`,
		);
	}
	return y * gridSize + x;
}

/**
 * @param {*[]} source the current list of values
 * @param {number} dimension the new dimension of the grid to map the current values to
 * @returns {*[]} the new list of values redistributed to a square with the new dimensions
 */
export function transformSquareToSize<T>(source: T[], dimension: number): T[] {
	const result: T[] = [];
	const resultSize = dimension ** 2;
	const sourceDimension = Math.floor(Math.sqrt(source.length));

	for (let i = 0; i < resultSize; i += 1) {
		const targetCoordinate = gridIndexToXYCoords(i, dimension);
		try {
			const sourceIndex = gridXYCoordsToIndex(targetCoordinate, sourceDimension);
			result[i] = source[sourceIndex] || null;
		} catch (e) {
			result[i] = null;
		}
	}
	return result;
}
//	0 is a valid number
export const isValidNumber = (value: unknown): value is number =>
	typeof value === 'number' && value !== undefined && value !== null && !Number.isNaN(value);

//	will return false for undefined, null, NaN, and Infinity
export const numberIsValidAndFinite = (value?: unknown): value is number =>
	isValidNumber(value) && value !== Infinity;

//	an empty string is NOT a valid string
export const isValidString = (value: unknown): value is string =>
	typeof value === 'string' && value !== undefined && value !== null && value !== '';

// returns true if position is on upper half of content excluding navbar, false otherwise
export const calcPopupDirection = (positionY: number) =>
	positionY - Dimensions.TOPBAR_HEIGHT - Dimensions.HEADER_HEIGHT <
	(window.innerHeight - Dimensions.TOPBAR_HEIGHT - Dimensions.HEADER_HEIGHT) / 2;

export const multitagIsJustifyLineCenter = (text?: string) => text?.includes('[jl3]');

export const multitagIsJustifyPageMiddle = (text?: string) => text?.includes('[jp3]');

export const getObjectKeyByValue = (obj: Record<string, unknown>, value: unknown) =>
	Object.keys(obj).find((key) => obj[key] === value);

export const validateNotEmptyString = (
	errorMessage: string,
): ((value: string) => ValidationResult) => {
	return (value) => {
		if (value.length === 0) {
			return { isError: true, errorMessage };
		}
		return { isError: false, errorMessage: '' };
	};
};

export const validateNumber = (errorMessage: string): ((value: number) => ValidationResult) => {
	return (value) => {
		if (!isValidNumber(value)) {
			return { isError: true, errorMessage };
		}
		return { isError: false, errorMessage: '' };
	};
};

export const isLaneImpacted = (blockage?: LaneBlockage | null): boolean | undefined =>
	blockage !== undefined &&
	blockage !== null &&
	((blockage.lanesAffected?.length !== undefined && blockage.lanesAffected?.length > 0) ||
		blockage.entranceRampAffected ||
		blockage.exitRampAffected ||
		blockage.insideShoulderAffected ||
		blockage.outsideShoulderAffected ||
		blockage.allLanesAffected);

export const stringsToComboboxOptions = (strs: string[]): ComboBoxOption[] =>
	strs.map(
		(str) =>
			({
				value: str,
				label: str,
			} as ComboBoxOption),
	);

/**
 * @author Sámal Rasmussen
 * @see https://stackoverflow.com/a/69042224
 * @param  {T} obj some object
 * @param  {K} prop a possible property of the object
 * @returns {boolean} true if obj is T && prop is a key of obj, else false
 */
export function hasOwnProperty<T, K extends PropertyKey>(
	obj: T,
	prop: K,
): obj is T & Record<K, unknown> {
	return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * converts a base64 encoded data url SVG image to a PNG image
 *
 * @param {string} svg data url of svg image
 * @param {number} width target width in pixel of PNG image
 * @returns {Promise<string>} resolves to png data url of the image
 */
export function svgToPng(svg: string, width: number, height?: number): Promise<string | null> {
	return new Promise((resolve) => {
		const img = document.createElement('img');
		img.onload = () => {
			document.body.appendChild(img);
			const canvas = document.createElement('canvas');
			document.body.removeChild(img);
			canvas.width = width;
			canvas.height = height ?? width;
			const ctx = canvas.getContext('2d');
			ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
			try {
				const data = canvas.toDataURL('image/png');
				resolve(data);
			} catch (e) {
				resolve(null);
			}
		};
		img.onerror = () => {
			resolve(null);
		};
		img.src = URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
	});
}

/**
 * Converts value from one unit to multiple
 * @param {number} value Numeric value of provided inUnit
 * @param {string} inUnit Unit for provided value e.g. "inches", "miles", "seconds"
 * @param {string[]} outUnits List of units to convert to, probably just ["feet", "inches"] in practice.
 * @returns {number[]} List of values corresponding to outUnits
 */
export function convertValueToManyUnits(
	value: number,
	inUnit: string,
	outUnits: string[],
): number[] {
	const inUnitLowerCase = inUnit.toLowerCase();
	const outUnitsLowerCase = outUnits.map((unit) => unit.toLowerCase());

	const values: number[] = [];

	if (outUnitsLowerCase.length === 1) return [value];

	let currentValue = value;

	outUnitsLowerCase.forEach((unit) => {
		const newVal = Math.floor(convert(currentValue, inUnitLowerCase).to(unit));

		values.push(newVal);
		currentValue -= Math.floor(convert(newVal, unit).to(inUnitLowerCase));
	});

	return values;
}

/**
 * Converts quantity value to a string containing its corresponding input units
 *
 * @param {QuantityDto} quantity Quantity object containing a value and its units.
 * @param {QuantityTypeDto} quantityType Quantity type containing the configured input units
 * @returns {string} A string reprsenting the quantity's value with the quantityType's units, e.g. "5 feet 4 inches".
 */
export function convertQuantityValueToString(
	quantity: QuantityDto,
	quantityType: QuantityTypeDto,
): string {
	const { value } = quantity;
	const units = quantityType.units?.toLowerCase();

	if (!value || !units) return 'N/A';

	const multiplierUnits = quantityType.multiplierUnits?.toLowerCase();
	const inUnits: string[] = [];

	if (multiplierUnits) inUnits.push(multiplierUnits);
	if (units) inUnits.push(units);

	const values = convertValueToManyUnits(value, units, inUnits);
	let valueWithUnitsString = '';

	inUnits.forEach((unit, index) => {
		valueWithUnitsString += `${values[index]} ${unit} `;
	});

	return valueWithUnitsString;
}

/**
 * Checks if at least one responding unit has valid arrived, assigned, and completed timestamps
 *
 * @param {RespondingUnitDto[]} units List of units to check timestamps
 * @returns {boolean} True if at least one unit has valid timestamps, false otherwise.
 */
export function doRespondingUnitsHaveValidTimestamps(units: RespondingUnitDto[]): boolean {
	return units.some(
		(unit) =>
			typeof unit.arrived === 'number' &&
			typeof unit.assigned === 'number' &&
			typeof unit.completed === 'number',
	);
}

/**
 * Checks if incident is Abandoned Vehicle AND at least one responding unit has valid timestamps.
 *
 * @param {RoadEventDto} event Event to check
 * @returns {boolean} True if event is abandoned vehicle and has valid timestamps
 */
export function isEventAbandonedVehicleWithValidTimestamps(event: RoadEventDto): boolean {
	return (
		event.eventType === EventTypes.ABANDONED_VEHICLE &&
		doRespondingUnitsHaveValidTimestamps(event.respondingUnits)
	);
}

export const isEventInRegion = (event: RoadEventDto, region: Region): boolean =>
	event?.locationDetails?.region?.includes(region) ?? false;
