import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { normalize } from 'normalizr';
import { messageIsSupportedBySign } from '@/utils/signUtils';
import {
	AreaOfInfluenceDto,
	GroupDto,
	MessageDto,
	SignDto,
	SignDtoNew,
	SignFieldDto,
	SignQueueDto,
} from '../../../typings/api';
import { NotificationErrorType } from '../../../typings/shared-types';
import { ConfigDMSApi } from '../../config/ConfigDMS';
import ConfigCARSx, { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import { AppSection, DMSView, SignsLoaderState } from '../../constants';
import { signSchema } from '../../schemas';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { addItem, removeItem } from '../redux-loaderAnim';
import { navigate } from '../redux-routing';
import { ThunkActionRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';
import { DMSActionType, setDMSLoading } from './dms-actions';
import { DmsEntities } from './dms-state';
import { getGroups, saveGroup } from './dms-actions-group';
import { isValidString } from '../../utils/utils';
import { selectCustomMessageById, selectSignById } from './dms-selectors';

export type GetSigns = Action<DMSActionType.GET_SIGNS>;

export interface GetSign extends Action<DMSActionType.GET_SIGN> {
	signId: number;
}

export interface SetSign extends Action<DMSActionType.SET_SIGN> {
	sign: SignDto;
}

export interface SetSigns extends Action<DMSActionType.SET_SIGNS> {
	signIds?: number[];
	entities?: DmsEntities;
}

export interface SetSignsFilterTypes extends Action<DMSActionType.FILTER_TYPES> {
	filterTypes: string[];
}

export interface SetSignQueue extends Action<typeof DMSActionType.SET_DMS_SIGN_QUEUE> {
	signQueue: SignQueueDto;
}

export interface SetSignActiveQueue extends Action<typeof DMSActionType.SET_SIGN_ACTIVE_QUEUE> {
	activeQueue: MessageDto[];
}

export interface GetSignQueue extends Action<typeof DMSActionType.GET_DMS_SIGN_QUEUE> {
	signId: number;
}

export interface PromoteQueueMessage extends Action<typeof DMSActionType.PROMOTE_QUEUE_MESSAGE> {
	signId: number;
	messageId: number;
}

export interface SaveSign extends Action<typeof DMSActionType.SAVE_SIGN> {
	sign: SignDto;
}

export interface DeleteSigns extends Action<DMSActionType.DELETE_SIGNS> {
	signIds: number[];
}

export interface SetSelectedMessage
	extends Action<DMSActionType.SET_DMS_SIGN_QUEUE_SELECTED_MESSAGE> {
	selectedMessage?: MessageDto;
}

export const setSelectedMessage = (selectedMessage?: MessageDto): SetSelectedMessage => {
	return {
		type: DMSActionType.SET_DMS_SIGN_QUEUE_SELECTED_MESSAGE,
		selectedMessage,
	};
};

export const setSignsFilters = (filterTypes: string[]): SetSignsFilterTypes => {
	return {
		type: DMSActionType.FILTER_TYPES,
		filterTypes,
	};
};

export interface AddCustomMessageToSigns extends Action<DMSActionType.ADD_CUSTOM_MESSAGE_TO_SIGNS> {
	customMessageId: number;
	signIds: number[];
	startTime: number;
	endTime: number;
}

export const getSigns =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_SIGNS });

		const url = new URL(ConfigDMSApi.SignsEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		dispatch(removeItem('signs', SignsLoaderState.fetch));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const signs = (await apiRequestReturn.response.json()) as SignDto[];
				const normalized = normalize(signs, [signSchema]);

				dispatch({
					type: DMSActionType.SET_SIGNS,
					signIds: normalized.result as number[],
					entities: normalized.entities,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const getSign =
	(signId: number): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_SIGN });

		const url = new URL(ConfigDMSApi.signById(signId), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		dispatch(removeItem('signs', SignsLoaderState.fetch));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const sign = (await apiRequestReturn.response.json()) as SignDto;
				dispatch({
					type: DMSActionType.SET_SIGN,
					sign,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const getSignQueue =
	(signId: number): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_DMS_SIGN_QUEUE });

		const url = new URL(ConfigDMSApi.signQueueById(signId), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		dispatch(removeItem('signs', SignsLoaderState.queue));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGN_QUEUE_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const signQueue = (await apiRequestReturn.response.json()) as SignQueueDto;

				dispatch({ type: DMSActionType.SET_DMS_SIGN_QUEUE, signQueue });
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGN_QUEUE_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGN_QUEUE_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const promoteSignMessage =
	(signId: number, messageId: number): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.PROMOTE_QUEUE_MESSAGE });

		const url = new URL(
			ConfigDMSApi.promoteMessageByIds(signId, messageId),
			APIConfig.endpointURLBase,
		);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages.login.route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const activeQueue = (await apiRequestReturn.response.json()) as SignQueueDto['activeQueue'];
				dispatch({ type: DMSActionType.SET_SIGN_ACTIVE_QUEUE, activeQueue });
				if (activeQueue) {
					dispatch(setSelectedMessage(activeQueue[0]));
				}
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: 'Server error when attempting to promote queue message',
				}),
			);
		}
	};

export const saveSign =
	(
		sign: SignDtoNew,
		aois: AreaOfInfluenceDto[],
		addToGroups: GroupDto[],
		removeFromGroups: GroupDto[],
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.SAVE_SIGN });
		dispatch(setDMSLoading(true));

		const newSign: SignDtoNew = {
			...sign,
			areasOfInfluence: aois,
		};

		const url = new URL(ConfigDMSApi.SaveSignEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(newSign),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		const savedSign: SignDto = (await apiRequestReturn.response?.json()) as SignDto;

		if (apiRequestReturn.response?.ok) {
			addToGroups.map((group) => {
				const groupSignIds = [
					...(group.signs?.map((gSign) => gSign.id) ?? []),
					savedSign.id,
				] as number[];
				return dispatch(saveGroup(group.name ?? '', groupSignIds, group.id));
			});
			removeFromGroups.map((group) => {
				const groupSignIds = [
					...(group.signs?.map((gSign) => gSign.id).filter((id) => id !== sign.id) ?? []),
				] as number[];
				return dispatch(saveGroup(group.name ?? '', groupSignIds, group.id));
			});

			dispatch({ type: DMSActionType.SET_SIGN, sign: savedSign });

			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: `${savedSign.name ?? 'Current sign'} saved successfully!` },
					5000,
				),
			);
			void dispatch(navigate(`${ConfigCARSx.Pages.dms.route}/${DMSView.table}`));
		} else {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error saving ${savedSign.name ?? 'current sign'}`,
						messages: apiRequestReturn.apiError ? [apiRequestReturn.apiError] : undefined,
					},
					5000,
				),
			);
		}
		if (savedSign.signConfig?.passwordSaved) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: `New sign password saved successfully!` },
					5000,
				),
			);
		} else if (isValidString(savedSign.signConfig?.password)) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Failed to save new sign password` },
					5000,
				),
			);
		}

		dispatch(setDMSLoading(false));
		return apiRequestReturn;
	};

export const deleteSigns =
	(signIds: number[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('sign', 'delete-sign'));
		dispatch({ type: DMSActionType.DELETE_SIGNS });
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigDMSApi.deleteSigns(signIds), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.status === 200) {
			await Promise.all([dispatch(getGroups()), dispatch(getSigns())]);

			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: `Sign ${signIds.toString()} Deleted Successfully`,
					},
					5000,
				),
			);
		}
		dispatch(removeItem('sign', 'delete-sign'));
		return apiRequestReturn;
	};

export const addCustomMessageToSigns =
	(
		customMessageId: number,
		signIds: number[],
		startTime: number,
		endTime: number,
		priority: number,
	): ThunkActionRoot<Promise<APIRequestReturn | void>> =>
	async (dispatch, getState): Promise<APIRequestReturn | void> => {
		dispatch({ type: DMSActionType.ADD_CUSTOM_MESSAGE_TO_SIGNS });

		const state = getState();

		const unsupportedSigns: SignDto[] = [];
		const customMessage = selectCustomMessageById(state, customMessageId);
		const supportedSignIds: number[] = [];

		signIds.forEach((signId) => {
			const sign = selectSignById(state, signId);
			if (sign) {
				if (messageIsSupportedBySign(customMessage, sign)) {
					supportedSignIds.push(signId);
				} else {
					unsupportedSigns.push(sign);
				}
			}
		});

		if (supportedSignIds.length === 0) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: 'Custom message is not supported by any signs in the selected group',
				}),
			);

			return {};
		}

		if (unsupportedSigns.length > 0) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title:
						'Custom message type was not compatible on the following signs and was not added to sign queues.',
					messages: [unsupportedSigns.map((sign) => `#${sign.id} ${sign.name}`).join(', ')],
				}),
			);
		}

		const url = new URL(
			ConfigDMSApi.addCustomMessageToSigns(customMessageId, supportedSignIds),
			APIConfig.endpointURLBase,
		);
		url.searchParams.set('start-time', startTime.toString());
		url.searchParams.set('end-time', endTime.toString());
		url.searchParams.set('priority', priority.toString());

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.ok) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: 'Custom message successfully added to compatible sign queues.' },
					5000,
				),
			);
		}
		if (apiRequestReturn.response?.status !== 200) {
			if (DebuggingConfig.showConsoleLogs) {
				console.error('error adding custom message to sign queues.');
			}
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `There was an error adding this custom message to sign queues.` },
					5000,
				),
			);
		}

		return apiRequestReturn;
	};

export const updateSignMessage =
	(
		signId: number,
		messageId: number,
		startTime: number,
		endTime: number,
		applyToall = false,
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.UPDATE_SIGN_MESSAGE });

		const url = new URL(ConfigDMSApi.UpdateSignMessageTimes, APIConfig.endpointURLBase);
		url.searchParams.set('message-id', messageId.toString());
		url.searchParams.set('start-time', startTime.toString());
		url.searchParams.set('end-time', endTime.toString());
		url.searchParams.set('apply-to-all', applyToall ? 'true' : 'false');

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.ok) {
			await dispatch(getSignQueue(signId));

			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: 'Message was successfully rescheduled' },
					5000,
				),
			);
		}
		if (apiRequestReturn.response?.status !== 200) {
			if (DebuggingConfig.showConsoleLogs) {
				console.error('error rescheduling message');
			}
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `There was an error rescheduling this message` },
					5000,
				),
			);
		}

		return apiRequestReturn;
	};

export const deleteMessageFromQueue =
	(
		signId: number,
		messageId: number,
		applyToall = false,
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.DELETE_SIGN_MESSAGE });

		const url = new URL(
			ConfigDMSApi.deleteMessageFromQueueEndpoint(messageId, applyToall),
			APIConfig.endpointURLBase,
		);

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.ok) {
			await Promise.all([dispatch(getSignQueue(signId)), dispatch(getSign(signId))]);

			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: 'Message was successfully deleted' },
					5000,
				),
			);
		}
		if (apiRequestReturn.response?.status !== 200) {
			if (DebuggingConfig.showConsoleLogs) {
				console.error('error deleting message');
			}
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `There was an error deleting this message` },
					5000,
				),
			);
		}

		return apiRequestReturn;
	};

export const getSignFields =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.GET_SIGN_FIELDS });
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigDMSApi.signFields, APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const signFields: SignFieldDto = (await apiRequestReturn.response?.json()) as SignFieldDto;
			dispatch({
				type: DMSActionType.SET_SIGN_FIELDS,
				signFields,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event fields:`, error);
			}
		}
		return apiRequestReturn;
	};
