import { css } from 'lit';
export const FieldnameToLabel = {
	zone: 'Zone',
	mileageStart: 'Starting Mileage',
	commission: 'Commission',
	mileageEnd: 'Ending Mileage',
	gps: 'GPS',
	rem: 'REM',
	dashCam: 'Dash Cam',
	ptzCam: 'PTZ Cam',
	active: 'Active',
	name: 'Name',
	dispositions: 'Highway Helper Disposition',
};

export const TimelineSortOrder = [
	FieldnameToLabel.name,
	FieldnameToLabel.commission,
	FieldnameToLabel.mileageStart,
	FieldnameToLabel.mileageEnd,
	FieldnameToLabel.gps,
	FieldnameToLabel.rem,
	FieldnameToLabel.dashCam,
	FieldnameToLabel.ptzCam,
	FieldnameToLabel.zone,
	FieldnameToLabel.dispositions,
];

export const HighlightedFieldNames = ['gps', 'rem', 'dashCam', 'ptzCam'];