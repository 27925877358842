import { css } from 'lit';
import { DebuggingConfig } from '@config/ConfigCARSx';
import { ComboBoxOption } from 'cra-web-components/components/combo-box/combo-box';
import { ChangeSetItem, HighwayHelperDto, TimelineEntry } from '../../../typings/api';
import { TimelineDay, TimelineDayEntryChange } from '../../../typings/shared-types';
import {
	FieldnameToLabel,
	HighlightedFieldNames,
	TimelineSortOrder,
} from '../../config/ConfigHHTimeline';
import { ConfigREMEventForm } from '../../config/ConfigREM';
import { LOCALE } from '../../constants';
import formatTimezoneSup from '../../utils/format-timezone-sup';
import { hasOwnProperty, isValidString } from '../../utils/utils';
import { RootState } from '../redux-store';

export const selectChangeSetItemToTimelineDayEntryChange = (
	changeSetName: string,
	changeSetItem: ChangeSetItem,
): TimelineDayEntryChange | undefined => {
	switch (changeSetItem.fieldName) {
		case 'active':
			return {
				subject: 'Active',
				description: `Patrol ${changeSetItem.value ? 'started' : 'ended'}`,
				highlighted: false,
			};
		case 'dispositionType': {
			const { value, previousValue } = changeSetItem;

			return {
				subject: 'Highway Helper Disposition',
				description: isValidString(value)
					? `Added disposition type ${value}`
					: `Removed disposition type ${previousValue as string}`,
				highlighted: false,
			};
		}
		case 'notes': {
			return isValidString(changeSetItem.value)
				? {
						subject: 'Operator note appended',
						description: `"${changeSetItem.value}"`,
						highlighted: true,
				  }
				: undefined;
		}
		case 'updated':
		case 'updatedBy':
		case 'createdBy':
		case 'created':
			break;
		default: {
			if (changeSetItem.fieldName && hasOwnProperty(FieldnameToLabel, changeSetItem.fieldName)) {
				const subject = FieldnameToLabel[changeSetItem.fieldName] as string;

				if (subject) {
					return {
						subject,
						description: changeSetItem.value as string,
						highlighted: HighlightedFieldNames.includes(changeSetItem.fieldName),
					};
				}
			}
			if (DebuggingConfig.showConsoleLogs) {
				console.warn(`unexpected '${changeSetName}' field name:`, changeSetItem);
			}
		}
	}
	return undefined;
};

export const selectHelperDataById = (
	state: RootState,
	helperID: number,
): HighwayHelperDto | undefined =>
	state.hh?.helpers?.find((helper) => {
		return helper.id === helperID;
	});

export const selectCommissionsToComboBoxOptions = (state: RootState): ComboBoxOption[] =>
	state.hh?.fields?.commissions?.map((comission) => ({
		// ID number is not needed for this combobox, as the label string is what is passed to the backend
		value: comission, // value: eventType.id.toString(),
		label: comission,
	})) ?? [];

export const selectPatrolZonesToComboBoxOptions = (state: RootState): ComboBoxOption[] =>
	state.hh?.fields?.zones?.map((zone) => ({
		// ID number is not needed for this combobox, as the label string is what is passed to the backend
		value: zone, // value: eventType.id.toString(),
		label: zone,
	})) ?? [];

export const selectDispositionsToComboBoxOptions = (state: RootState): ComboBoxOption[] =>
	state.hh?.fields?.dispositionTypes?.map((disposition) => ({
		value: disposition.name,
		label: `${disposition.name} - ${disposition.description}`,
		recommended: false,
	})) ?? [];

export const selectHHTimelineByDay = (state: RootState): TimelineDay[] =>
	(state.hh.helperTimeline?.timeline?.entries as TimelineEntry[])?.reduce(
		(timelineDays: TimelineDay[], timelineEntry: TimelineEntry): TimelineDay[] => {
			//	is there already a container for the day this change occurred on?
			const dateString = new Date(timelineEntry.timestamp).toLocaleDateString(
				LOCALE,
				ConfigREMEventForm.timelineDateFormatOptions,
			);
			const lastTimelineDay = timelineDays[timelineDays.length - 1];
			let timelineDay: TimelineDay;
			if (lastTimelineDay?.dateString === dateString) {
				timelineDay = lastTimelineDay;
			} else {
				timelineDay = {
					dateString,
					entries: [],
				};
				timelineDays.push(timelineDay);
			}
			//	what changes occured on this day?
			const timeString = formatTimezoneSup(
				new Date(timelineEntry.timestamp).toLocaleTimeString(
					LOCALE,
					ConfigREMEventForm.timelineTimeFormatOptions,
				),
			);
			const user = timelineEntry.changeSets?.[0].changeSetItems?.[0]
				? timelineEntry.changeSets?.[0].changeSetItems?.[0].createdBy
				: null;
			const changes: TimelineDayEntryChange[] = [];
			timelineEntry.changeSets?.forEach((changeSet) => {
				changeSet.changeSetItems?.forEach((changeSetItem: ChangeSetItem) => {
					const timelineDayEntryChange = selectChangeSetItemToTimelineDayEntryChange(
						changeSet.name as string,
						changeSetItem,
					);
					if (timelineDayEntryChange !== undefined) {
						changes.push(timelineDayEntryChange);
					}
				});
			});

			if (changes.length === 0) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn(
						`no ChangeSetItems could be resolved to TimelineDayEntryChanges for "${dateString} ${timeString}"`,
					);
				}
			}

			changes.sort((a, b) => {
				return TimelineSortOrder.indexOf(a.subject) - TimelineSortOrder.indexOf(b.subject);
			});

			if (user && changes.length) {
				timelineDay.entries.push({
					timeString,
					user,
					changes,
				});
			}

			return timelineDays;
		},
		[] as TimelineDay[],
	);

export const selectHHNamesToComboBoxOptions = (state: RootState): ComboBoxOption[] =>
	state.hh.fields?.names?.map((name) => ({ value: name, label: name, recommended: false })) ?? [];
